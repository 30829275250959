.navLink {
  cursor: pointer;
}

.headingTitle {
  font-size: 2em;
}


@media only screen and (max-width: 760px) {

  .headingTitle {
    font-size: 3em;
  }

}

